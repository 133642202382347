<template>
    <div class="content">
        <el-carousel :class="gongzuoShow ? 'gongzuozu' : ''" :autoplay="zidong" :interval="8000"
            :style="{ height: banner1herght + 'px' }">
            <el-carousel-item v-for="(item, key) in topBannerArr" :key="key" :style="{ height: banner1herght + 'px' }">
                <div class="dingweititle">
                    <!-- <div class="title">{{ item.title }}</div> -->
                    <div v-if="photo">
                        <img style="width: 100%;" class="head_banner_img imgnone" @load="setBannerHeight"
                            @click="toBannerPath(item)" :src="photo" alt="">
                    </div>
                    <div v-else>
                        <img style="width: 100%;" class="head_banner_img imgnone" @load="setBannerHeight"
                            @click="toBannerPath(item)" :src="item.photo" alt="" v-if="!item.videourl1">
                        <video :src="item.videourl1" v-else controls style="width: 100%;height: 100%;">
                            <source src="movie.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import request from '@/utils/request';

export default {
    props: {
        types: {
            type: Number,
            default: 0
        },
        ziding: {
            type: Boolean,
            default: false
        },
        urls: {
            type: String,
            default: ''
        },
        zidong: {
            type: Boolean,
            default: false
        },
        photo: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            banner1herght: 560,
            topBannerArr: [],
            gongzuoShow: false
        }
    },
    watch: {
        types: function (val) {
            if (this.ziding) {
                this.toapigroupbanner(val)
            } else if (this.urls && this.urls == 'atmospheric') {
                this.toapigroupbanners(val)
            } else {
                this.getTopBannerData()
            }
        }
    },
    mounted() {
        if (this.ziding) {
            this.toapigroupbanner(this.types)
        } else if (this.urls && this.urls == 'atmospheric') {
            this.toapigroupbanners(this.types)
        } else {
            this.getTopBannerData()
        }
        if (this.$route.path == '/WorkingGroups') {
            this.gongzuoShow = true
        } else {
            this.gongzuoShow = false
        }
    },
    methods: {
        setBannerHeight(event) {
            this.banner1herght = event.target.offsetHeight;
            if (this.$route.path == '/'||this.$route.path == '/memberm4') {
                window.scrollTo(0, 0)
                return
            } else {
                window.scrollTo(0, this.banner1herght-90)
            }
        },
        getTopBannerData() {
            request({
                url: 'apibanner',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    type: this.types
                }
            }).then(res => {
                this.topBannerArr = res.data;
            })
        },
        toapigroupbanner(id) {
            request({
                url: 'apigroupbanner',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    belong: id
                }
            }).then(res => {
                this.topBannerArr = res.data
            })
        },
        toapigroupbanners(id) {
            request({
                url: 'apituanbanner',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    belong: id
                }
            }).then(res => {
                this.topBannerArr = res.data
            })
        },
        toBannerPath(item) {
        }
    }
}
</script>
<style lang="scss" scoped>
// 移动端
@media screen and (max-width: 1025px) {

    .dingweititle {
        position: relative;
        width: 100%;
        height: 100%;

        .title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px !important;
            color: #fff;
            font-weight: bold;
        }
    }
}

::v-deep .el-carousel__container {
    height: 100% !important;
}

::v-deep .el-carousel__item {
    transition: 3s !important;
    /* 过渡效果持续时间为1秒 */
}

.dingweititle {
    position: relative;
    width: 100%;
    height: 100%;

    .title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        color: #fff;
        font-weight: bold;
    }

}

.gongzuozu {
    ::v-deep .el-carousel__indicators {
        display: none !important;
    }
}
</style>