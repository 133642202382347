<!-- 注册页 -->
<template>
    <div class="content">
        <heads></heads>
        <div class="box">
            <img class="banner" src="@/assets/login/bg.jpg" alt="">
            <div class="box_cntent">
                <div class="banner1_box">
                    <div class="dingwei">
                        <div>{{ form_json[0].value }} {{ form_json[1].value }}</div>
                        <div>{{ form_json[2].value }}</div>
                        <!-- <div>{{ form_json[5].value }}</div> -->
                    </div>
                    <img class="banner1" src="@/assets/login/bj1.jpg" alt="">
                </div>
                <div class="login_box">
                    <div class="title">
                        Welcome To AERSS
                    </div>
                    <div class="form_box">
                        <div class="item_box" v-for="(item,key) in form_json" :style="item.props=='institution'?{width:'100%'}:''">
                            <div class="item_title"><span class="xing" v-if="item.props!='institution'">*</span>{{ item.title }}</div>
                            <div class="inp_box" v-if="item.type == 'password'">
                                <input autocomplete="off" :type="passwordShow ? 'text' : 'password'" :placeholder="item.placeholder"
                                    v-model="item.value">
                                <img class="icon" @click="passwordShow = !passwordShow" v-if="!passwordShow"
                                    src="@/assets/login/kejian.png" alt="">
                                <img class="icon" @click="passwordShow = !passwordShow" v-else
                                    src="@/assets/login/biyan.png" alt="">
                            </div>
                            <!-- <input type="text" :placeholder="item.placeholder" v-model="item.value"> -->
                            <el-select v-else-if="item.type == 'select'" class="inp_box" v-model="item.value"
                                :placeholder="item.placeholder" style="width: 100%; padding: 7px 0;">
                                <el-option style="font-size: 18px;" v-for="item in item.options" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-select  v-else-if="item.type == 'filterable'" class="inp_box" v-model="item.value"
                                filterable remote reserve-keyword :placeholder="item.placeholder"
                                :remote-method="remoteMethod" :loading="item.loading"
                                style="width: 100%; padding: 7px 0;">
                                <el-option v-for="item in item.options" style="font-size: 18px;" :key="item.name_cn" :label="item.name_cn"
                                    :value="item.name_cn">
                                </el-option>
                            </el-select> -->
                            <div class="inp_box" v-else :style="item.props=='institution'?{width:'40%'}:''">
                                <input type="text" autocomplete="off" :placeholder="item.placeholder" v-model="item.value">
                            </div>
                        </div>
                    </div>
                    <div class="btn_box">
                        <div class="btn" @click="toRegister">Register</div>
                        <div class="text">* Required</div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import request from '@/utils/request';
import heads from '@/components/heads.vue';
import foots from '@/components/foots.vue';
export default {
    components: {
        foots,
        heads,
    },
    data() {
        return {
            passwordShow: false,
            form_json: [
                {
                    title: 'First Name',
                    placeholder: 'Please input first name. ',
                    value: '',
                    props: 'first_name',
                    type: 'text'
                },
                {
                    title: 'Last Name:',
                    placeholder: 'Please input last name. ',
                    value: '',
                    props: 'last_name',
                    type: 'text'
                },
                {
                    title: 'Affiliation',
                    placeholder: 'Please enter your institution',
                    value: '',
                    props: 'institution',
                    type: 'text'
                },
                // {
                //     title: 'Gender',
                //     placeholder: 'Select',
                //     value: '',
                //     props: 'gender',
                //     type: 'select',
                //     options: []
                // },
                // {
                //     title: 'Title',
                //     placeholder: 'Select',
                //     value: '',
                //     props: 'title',
                //     type: 'select',
                //     options: []
                // },
                // {
                //     title: 'Region',
                //     placeholder: 'Please enter your region',
                //     value: '',
                //     props: 'region',
                //     type: 'filterable',
                //     loading: false,
                //     options: []
                // },
                {
                    title: 'E-mail',
                    placeholder: 'Recommend using the official website email or educational email of the institution',
                    value: '',
                    props: 'email',
                    type: 'text'
                },
                {
                    title: 'Password',
                    placeholder: '',
                    value: '',
                    props: 'password',
                    type: 'password'
                },
               
            ]
        }
    },
    mounted() {
        Array.from(document.getElementsByClassName('el-select')).forEach((item) => {
            item.children[0].children[0].removeAttribute('readOnly')
            item.children[0].children[0].onblur = function () {
                let _this = this
                setTimeout(() => {
                    _this.removeAttribute('readOnly')
                }, 200)
            }
        })
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            if (res.code == 200) {
                this.form_json[2].options = res.data.sex
                this.form_json[3].options = res.data.position
                // this.form_json[5].options = res.data.area
            }
        })
    },
    methods: {
        toRegister() {
            let flag = true;
            for (let i = 0; i < this.form_json.length; i++) {
                const item = this.form_json[i];
                if (!item.value&&item.props!='institution') {
                    this.$message.error('Please enter ' + item.title + '!');
                    flag = false;
                    break; // 立即跳出循环
                }
            }
            // 验证邮箱
            // if (flag) {
            //     let email = this.form_json[6].value;
            //     let reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            //     if (!reg.test(email)) {
            //         this.$message.error('Please enter the correct email format!');
            //         flag = false;
            //     }
            // }
            if (flag) {
                let data = {};
                for (let i = 0; i < this.form_json.length; i++) {
                    const item = this.form_json[i];
                    data[item.props] = item.value;
                }
                request({
                    url: 'apiregister',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data,
                }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        setTimeout(() => {
                            this.$router.push('/login')
                        }, 1000)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        // remoteMethod(query) {
        //     if (query !== '') {
        //         this.form_json[5].loading = true;
        //         request({
        //             url: 'find_country_data',
        //             method: 'post',
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //             },
        //             data: {
        //                 name_cn: query
        //             }
        //         }).then(res => {
        //             if (res.code == 200) {
        //                 this.form_json[5].loading = false;
        //                 this.form_json[5].options = res.data
        //             }
        //         })
        //     } else {
        //         this.form_json[5].options = [];
        //     }
        // }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar {
    opacity: 1 !important;
}

::v-deep .el-scrollbar__bar {
    opacity: 1 !important;
}

::v-deep .el-input__inner {
    border: none !important;
    border-radius: 30px;
}

::v-deep .el-textarea__inner {
    border: none !important;
    min-height: 150px !important;
}

::v-deep .el-input__prefix {
    padding-right: 5px;
}

::v-deep .el-input__suffix {
    padding-right: 5px;
}

@media screen and (max-width: 1025px) {
    ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 10px !important;
    }

    .content>.box {
        margin-top: 0px !important;
        width: 100% !important;
    }

    .banner {
        object-fit: cover;
    }

    .box_cntent {
        padding: 4% 3% !important;
        display: flex;
        align-items: center;
        flex-direction: column;

        .banner1 {
            display: none;
        }

        .login_box {
            margin-left: 0 !important;
            margin-right: 0 !important;

            .title {
                font-size: 20px !important;
                font-weight: bold;
                color: #005083;
                margin-top: 16px !important;
                text-align: center;
            }

            .item_box {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px !important;

                .item_title {
                    flex: 2;
                    font-size: 16px !important;
                    font-weight: 400 !important;

                    .xing {
                        color: #005083;
                        margin-right: 10px;
                    }
                }

                .inp_box {
                    flex: 2;
                    margin: 0 !important;
                    padding: 10px !important;
                    overflow: hidden !important;

                    input {
                        font-size: 14px !important;
                        width: 90% !important;
                    }

                    .icon {
                        width: 15px !important;
                        height: 15px !important;
                        cursor: pointer;
                    }
                }

            }

            .btn_box {
                width: 100% !important;

                .btn {
                    border-radius: 10px !important;
                    width: 100% !important;
                    margin-bottom: 10px !important;
                    height: 45px !important;
                    font-size: 16px !important;
                    font-weight: 400 !important;
                }

                .text {
                    display: none !important;
                }
            }
        }
    }

}

.content {
    overflow: hidden;
}

.banner {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -1;
}

.box_cntent {
    z-index: 9;
    padding: 100px 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .banner1_box {
        position: relative;

        .banner1 {
            width: 600px;
        }

        .dingwei {
            position: absolute;
            left: 200px;
            top: 240px;
            z-index: 9999;

            div {
                height: 50px;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }

    .login_box {
        flex: 1;
        max-width: 600px;
        margin-left: 20px;

        .title {
            font-size: 36px;
            font-weight: bold;
            color: #005083;
            margin-top: 16px;
        }

        .form_box {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .item_box {
                width: 45%;
                margin-bottom: 19px;

                .item_title {
                    font-size: 20px;
                    font-weight: bold;

                    .xing {
                        color: #005083;
                        margin-right: 10px;
                    }
                }

                .inp_box {
                    margin-top: 10px;
                    overflow: hidden;
                    background: #FFFFFF;
                    box-shadow: 0px 19px 35px 0px rgba(0, 47, 100, 0.04);
                    border-radius: 20px;
                    padding: 18px 20px;
                    padding-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    input {
                        border-radius: 20px;
                        border: none;
                        outline: none;
                        padding-left: 20px;
                        font-size: 14px;
                        color: #222222;
                        flex: 1;
                    }


                    .icon {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .btn_box {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .btn {
                background: #005083;
                border-radius: 20px;
                width: 45%;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }

            .text {
                font-size: 16px;
                font-family: Montserrat;
                font-weight: 400;
                color: #005ED2;
                margin-left: 20px;
            }
        }
    }

}
</style>