<template>
    <div>
        <div class="loading" ref="myDiv" v-if="loadingShow">
            <div class="box">
                <img class="logo" src="@/assets/index/logo.png" alt="">
                <img class="banner" src="@/assets/index/bg1.png" alt="">
                <div class="text">
                    <span style="font-size: 60px">30</span>%
                </div>
            </div>
            <div class="box">
                <img class="logo" src="@/assets/index/logo.png" alt="">
                <img class="banner" src="@/assets/index/bg2.png" alt="">
            </div>
            <div class="box">
                <img class="banner" src="@/assets/index/bg3.jpg" alt="">
            </div>
            <div class="box">
                <img class="banner" src="@/assets/index/bg4.jpg" alt="">
            </div>
        </div>
        <div class="conten" v-else>
            <div class="box">
                <banner :types="1"></banner>
                <div class="box_bottom">
                    <div v-for="(item, key) in topDatas" :key="key" class="box_item fangda">
                        <div class="value">
                            <animate-number duration="1500" v-if="item.remark" from="0" :to="item.remark"
                                :key="item.remark" class="number"></animate-number><span v-if="item.danwei">{{
                                    item.danwei }}</span>
                        </div>
                        <div class="name">{{ item.title }}</div>
                    </div>
                </div>
                <heads></heads>
            </div>
            <div class="box2">
                <div class="title">News</div>
                <div class="carousel_box">
                    <div style="width: 100%;display: flex;overflow: hidden;align-items: center;position: relative;">
                        <div class="banner_boxdingwei leftsss">
                            <div class="fangda"
                                v-show="lunboNum == 0 ? index == bannerArr.length - 1 : index == lunboNum - 1"
                                v-for="(item, index) in bannerArr" :key="index" @click="toNewsDetail(item.id)">
                                <div class="image-container">
                                    <span>
                                        <p style="margin-bottom: 12px; font-size: 14px;">published：{{ item.update_time |
                                            timestampToTime }}</p>
                                        {{ item.title }}
                                    </span>
                                </div>
                                <img class="banner" :src="item.photo" alt="">
                            </div>
                        </div>
                        <div class="iconBox icon_leftss">
                            <div @click="lunboNum == 0 ? lunboNum = bannerArr.length - 1 : lunboNum = lunboNum - 1">
                                <i class="el-icon-arrow-left"></i>
                            </div>
                        </div>
                        <div class="banner_boxdingwei contents">
                            <transition name="fade" mode="in-out">
                                <div v-if="lunboNum === key" v-for="(item, key) in bannerArr" :key="key"
                                    @click="toNewsDetail(item.id)" class="fangda">
                                    <div class="image-container">
                                        <span>
                                            <p style="margin-bottom: 12px; font-size: 14px;">published：{{
                                                item.update_time |
                                                timestampToTime }}</p>
                                            {{ item.title }}
                                        </span>
                                    </div>
                                    <img class="banner" :src="item.photo" alt="">
                                </div>
                            </transition>
                        </div>
                        <div class="iconBox icon_rightss">
                            <div @click="lunboNum == bannerArr.length - 1 ? lunboNum = 0 : lunboNum = lunboNum + 1">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div class="banner_boxdingwei rightsss">
                            <div class="fangda"
                                v-show="lunboNum == bannerArr.length - 1 ? index == 0 : index == lunboNum + 1"
                                v-for="(item, index) in bannerArr" :key="index" @click="toNewsDetail(item.id)">
                                <div class="image-container">
                                    <span>
                                        <p style="margin-bottom: 12px; font-size: 14px;">published：{{ item.update_time |
                                            timestampToTime }}</p>
                                        {{ item.title }}
                                    </span>
                                </div>
                                <img class="banner" :src="item.photo" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="foots_btnss">
                        <div v-for="(item, key) in bannerArr" :class="key == lunboNum ? 'actives' : ''"
                            @click="lunboNum = key">
                        </div>
                    </div>
                </div>
                <div class="carousel_box1" style="display: none;">
                    <el-carousel :autoplay="false" :style="{ height: banner1herght + 'px' }">
                        <el-carousel-item v-for="(item, key) in bannerArr" :key="key"
                            :style="{ height: banner1herght + 'px' }">
                            <img class="banner" @load="setBannerHeight" @click="toNewsDetail(item.id)" :src="item.photo"
                                alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="box3">
                    <div class="but seeMore" @click="toNewsList">
                        <span>See More</span>
                        <img style="width: 20%;" src="@/assets/index/right_icon.png" alt="">
                    </div>
                </div>
            </div>

            <div class="box4">
                <div class="title">Working Groups</div>
                <div class="item_box">
                    <div v-for="(item, key) in apiworklist" :key="key" class="item" @click="toWorkingGroups(item.id)">
                        <img src="@/assets/index/qunhuiyishixi.png" style="width: 44px;margin: 30px;margin-bottom: 0;"
                            alt="">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.remark }}</div>
                        <div class="mengban"
                            style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;border-radius: 20px;overflow: hidden;">
                            <img style="width: 100%;height: 100%;" :src="item.photo" alt="">
                        </div>
                    </div>
                    <div class="item" @click="toWorkingGroups(apiworklist[0].id)"
                        style="background-color: #E5EDF2;display: flex;align-items: center;justify-content: center;flex-direction: row;">
                        <span style="font-size: 16px;color: #333;font-weight: 500;">See More</span>
                        <img style="width: 18px;margin-left: 10px;" src="@/assets/index/right_icon.png" alt="">
                    </div>
                </div>
            </div>
            <div id="box5"></div>
            <div class="box5" :style="aerssShow ? 'background:none' : ''">
                <div v-if="!aerssShow" class="yuan yuan1" @click="todiqiuyi()"></div>
                <div v-if="!aerssShow" class="yuan yuan2" @click="todiqiuyi()"></div>
                <div v-if="!aerssShow" class="yuan yuan3" @click="todiqiuyi()"></div>
                <div v-if="!aerssShow" class="earth">
                    <img class="earth" @click="todiqiuyi()" src="@/assets/index/earth.png" alt="">
                    <img class="wenzi" :src="diqiuImg" alt="">
                </div>
                <div v-if="aerssShow" class="aerssbox"
                    style="display: flex;align-items: center;justify-content: center;background-color: #fff;"
                    @click="aerssShow = !aerssShow">
                    <img src="@/assets/1.png" style="height: 90%;object-fit: fill !important;" class="imgnone" alt="">
                </div>
            </div>

        </div>
        <!-- 移动端 -->
        <foots v-if="!loadingShow"></foots>
    </div>
</template>

<script>
import request from '@/utils/request';
import foots from '@/components/foots.vue';
import heads from '@/components/heads.vue';
import banner from '@/components/banner.vue';

export default {
    components: {
        foots,
        heads,
        banner
    },
    data() {
        return {
            loadingShow: false,
            banner1herght: 0,
            topBannerArr: [],
            topDatas: [],
            bannerArr: [],
            bannerNum: 0,
            apiworklist: [],
            aerssShow: false,
            diqiuShow: false,
            lunboNum: 0,
            banner1herght: 0,
            bannerkey: 0,
            diqiuImg: ''
        }
    },
    mounted() {
        this.loading();
        this.getTopData();
        this.getNewsData();
        this.getClassifyData();
        this.getinitdata()
    },

    methods: {
        setBannerHeight(event) {
            this.banner1herght = event.target.offsetHeight;
        },
        todiqiuyi() {
            this.aerssShow = true
        },
        getinitdata() {
            request({
                url: 'init_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.diqiuImg = res.data.mapstr
            })
        },
        // 轮播图
        handBannerNum(val) {
            this.bannerNum = val;
        },
        // loading加载
        loading() {
            //判断是否是移动端
            const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
            if (isMobile) {
                this.loadingShow = false;
                return
            }
            if (!this.loadingShow) {
                return
            }
            if (localStorage.getItem('loadingShow')) {
                this.loadingShow = false;
                return
            }
            // 动画滚动到底部
            const div = this.$refs.myDiv;
            const duration = 1000; // 滚动到底部的持续时间（单位：毫秒）
            const start = div.scrollTop;
            const end = div.scrollHeight - div.clientHeight;
            const change = end - start;
            let currentTime = 0;
            const increment = 1; // 每次滚动的增量（单位：像素）
            const animateScroll = () => {
                currentTime += increment;
                const val = Math.easeInOutQuad(currentTime, start, change, duration);
                div.scrollTop = val;
                if (currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
            };
            Math.easeInOutQuad = function (t, b, c, d) {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            };
            animateScroll();
            // 判断到底部后隐藏loading
            div.onscroll = () => {
                if (div.scrollTop === div.scrollHeight - div.clientHeight) {
                    this.loadingShow = false;
                    // 本地存储
                    localStorage.setItem('loadingShow', '1');
                }
            };
        },
        // 获取顶部目录数据
        getTopData() {
            request({
                url: 'apiyewu',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.topDatas = res.data.data;
            })
        },
        // 获取news数据
        getNewsData() {
            // news数据
            request({
                url: 'apishuofalist',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.bannerArr = res.data.data;
            })
        },
        // 获取分类数据
        getClassifyData() {
            request({
                url: 'apiworklist',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.apiworklist = res.data.data;
            })
        },
        toNewsList() {
            this.$router.push('/news');
        },
        toWorkingGroups(id) {
            if (id) {
                this.$router.push('/WorkingGroups?id=' + id);
            } else {
                this.$router.push('/WorkingGroups');
            }
        },
        toBannerPath(item) {
            if (item.videourl) {
                window.open(item.videourl)
            }
        },
        toNewsDetail(id) {
            this.$router.push('/newsDetail?id=' + id);
        },
    },
    watch: {
        aerssShow(val) {
            if (val) {
                document.getElementById('box5').scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__indicators--outside {
    margin-top: 40px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
}



// 移动端
@media screen and (max-width: 1025px) {
    .carousel_box {
        display: none !important;
    }

    .loading {
        height: 200vh !important;

        .box {
            .text {
                bottom: 30px !important;
                font-size: 20px !important;

                span {
                    font-size: 20px !important;
                }
            }

            .banner {
                height: auto !important;
            }
        }
    }

    .conten {
        .box {
            padding-top: 60px !important;

            .box_bottom {
                position: static !important;
                transform: translate(0, 0) !important;
                height: auto !important;
                width: 100% !important;
                flex-wrap: wrap !important;
                align-items: baseline !important;
                justify-content: flex-start !important;
                background-color: #F1F5F8 !important;
                border-radius: initial !important;

                // 超出换行
                .box_item {
                    width: 30% !important;
                    display: inline-block;
                    margin-right: 5%;

                    .number {
                        font-size: 30px !important;
                    }

                    .name {
                        margin-top: 0 !important;
                    }
                }

                .box_item:nth-child(3n) {
                    margin-right: 0 !important;
                }
            }
        }

        .box2 {
            padding-top: 20px !important;
            height: auto !important;

            .title {
                font-size: 25px !important;
                margin-bottom: 20px !important;
                margin-top: 20px !important;
            }

            .carousel_box {
                display: none;
            }

            .carousel_box1 {
                display: block !important;
                padding: 0 10px;

                .banner {
                    height: auto !important;
                    width: 100% !important;
                    border-radius: 10px !important;
                }
            }
        }



        .box3 {
            margin-top: 20px !important;

            .but {
                padding: 5px 11px !important;
            }
        }

        .box4 {
            width: 100% !important;

            .title {
                font-size: 25px !important;
                text-align: center !important;
                margin: 20px 0 !important;
            }

            .item_box {
                justify-content: space-between !important;
                margin-top: 10px !important;

                .item {
                    width: 45% !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 160px !important;
                    flex-basis: auto !important;
                    margin: 10px 0 !important;

                    img {
                        margin: 10px !important;
                    }

                    .title {
                        font-size: 18px !important;
                        margin-bottom: 0 !important;
                        font-weight: 400 !important;
                        margin: 5px 10px !important;
                        text-align: left !important;
                    }

                    .text {
                        font-size: 15px !important;
                        margin: 5px 10px !important;
                    }
                }

                .item:nth-child(2n) {
                    margin-right: 3vw !important;
                }

                .item:nth-child(1n) {
                    margin-left: 3vw !important;
                }
            }
        }

        .box5 {
            max-height: 400px !important;
            margin-top: 20px !important;
            padding: 0 !important;
            display: flex;
            width: 100vw !important;

            .earth {
                width: 250px !important;
                height: 250px !important;

                .wenzi {
                    position: absolute;
                    top: 30%;
                    left: -20% !important;
                    width: 50% !important;
                }
            }

            .aerss {
                width: 100% !important;
                height: 380px !important;
            }

            .aerssbox {
                width: 100% !important;
                height: 100% !important;
                text-align: center !important;
            }
        }
    }
}

.aerssbox {
    width: 100% !important;
    height: 100% !important;
    text-align: center !important;
}

.loading {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    .box {
        position: relative;
        width: 100%;

        .logo {
            width: 18%;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
        }

        .banner {
            width: 100%;
            height: 100vh;
        }

        .text {
            color: #fff;
            font-size: 32px;
            z-index: 5;
            position: absolute;
            bottom: 96px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.loading::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}

.conten {
    width: 100%;

    .box {
        width: 100%;
        position: relative;

        .banner {
            width: 100%;
        }

        .box_bottom {
            z-index: 99;
            width: 74%;
            height: 150px;
            background-color: #fff;
            border-radius: 10px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -75px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .box_item {
                text-align: center;

                .value {
                    font-size: 38px;
                    color: #005083;
                    font-weight: 500;
                }

                .name {
                    font-size: 16px;
                    color: #111;
                    margin-top: 10px;
                }
            }
        }

        .hand_box_active {
            background-color: #005083 !important;
            border: none !important;
            z-index: 99;
        }

        .head_box {
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #ffffff64;
            position: fixed;
            top: 0;
            display: flex;
            align-items: center;
            background-color: rgba($color: #000, $alpha: .1);
            justify-content: space-between;
            z-index: 99;

            .left {
                height: 53px;
                margin: 0 140px 0 60px;
            }

            .center {
                width: 52%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                margin-right: 144px;

                div {
                    cursor: pointer;
                    position: relative;
                    color: #fff;

                    &:hover {
                        color: #ccc;
                    }
                }
            }

            .right {
                display: flex;
                margin-right: 6%;

                img {
                    height: 44px;
                    margin-right: 18px;
                    cursor: pointer;
                }

                .right_btn_box {
                    display: flex;
                    border-radius: 21px;
                    border: 2px solid rgba($color: #ffffff, $alpha: .3);
                    width: 159px;
                    height: 41px;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    color: #fff;
                    flex-shrink: 0;
                    cursor: pointer;
                }
            }

        }
    }

    .box2 {
        background: linear-gradient(to bottom, #F1F5F8, #fff);
        height: calc(100vh);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .title {
            margin-top: 70px;
            font-size: 45px;
            font-weight: 600;
            color: #222222;
            text-align: center;
        }

        .carousel_box {
            display: flex;
            flex-direction: column;

            .foots_btnss {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 60px;

                div {
                    width: 38px;
                    height: 2px;
                    background: rgba($color: #000000, $alpha: .1);
                    margin-right: 20px;
                    cursor: pointer;
                }

                .actives {
                    background: #005083;
                }
            }

            .banner_boxdingwei {
                position: relative;
                width: 45%;
                height: 344px;
                text-align: center;

                div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
                    border-radius: 20px;
                    text-align: left;

                    span {
                        display: block;
                        position: absolute;
                        bottom: 30px;
                        font-size: 28px;
                        font-family: Montserrat;
                        font-weight: bold;
                        color: #FFFFFF;
                        width: 80%;
                        left: 30px;
                    }
                }

                .banner {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }

            .fade-enter-active,
            .fade-leave-active {
                transition: opacity 1.5s;
            }

            .fade-enter,
            .fade-leave-to {
                opacity: 0;
            }

            .image-container {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .1));
                border-radius: 20px;
                text-align: left;
            }

            .banner {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            .contents {
                margin: auto;
            }

            .iconBox {
                display: flex;
                height: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 18px;

                div {
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    background-color: #fff;
                    color: #005083;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 16px;
                    font-size: 20px;
                    cursor: pointer;

                    &:hover {
                        background-color: #005083;
                        color: #fff;
                    }
                }
            }

            .leftsss {
                position: absolute;
                left: -26%;
                top: 0;
            }

            .icon_leftss {
                position: absolute;
                left: 21%;
                top: 0;
            }

            .rightsss {
                position: absolute;
                right: -26%;
                top: 0;
            }

            .icon_rightss {
                position: absolute;
                right: 21%;
                top: 0;
            }
        }
    }

    .box3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        color: #333;

        .but {
            padding: 5px 11px;
            border: 2px solid #005083;
            border-radius: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }
    }

    .box4 {
        width: 1300px;
        margin: auto;

        .title {
            font-size: 45px;
            font-weight: 600;
            color: #222222;
        }

        .item_box {
            margin-top: 72px;
            display: flex;
            flex-wrap: wrap;
            /* 允许自动换行 */

            .item {
                height: 240px;
                border-radius: 20px;
                flex-basis: calc(31.333% - 10px);
                margin-bottom: 40px;
                cursor: pointer;
                background-color: #005083;
                position: relative;
                transition: all .5s;

                .title {
                    font-size: 28px;
                    margin-top: 40px;
                    margin-left: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-right: 42px;
                    // 超出两行省略
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .text {
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-left: 30px;
                    margin-right: 42px;
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .mengban {
                z-index: 0;
                opacity: 0;
            }

            .item:hover {
                background-color: #000 !important;

                .mengban {
                    opacity: .5;
                }
            }

            .item:nth-child(3n+2) {
                margin: 0 40px;
                /* 第三个项目的右侧边距设为0，每行最后一个项目无右侧边距 */
            }
        }
    }

    .box5 {
        margin-top: 100px;
        height: calc(100vh - 80px);
        overflow: hidden;
        width: 100%;
        background-color: #DBE4E9;
        position: relative;

        .yuan1,
        .yuan2,
        .yuan3,
        .earth {
            position: absolute;
            //垂直水平剧中
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: height 2s ease-in-out;

            .wenzi {
                position: absolute;
                top: 50%;
                left: -30%;
                width: 60%;
            }
        }

        .earth {
            width: 700px;
        }

        .yuan1 {
            background-color: #9BB9CB;
        }

        .yuan2 {
            background-color: #AEC6D4;
        }

        .yuan3 {
            background-color: #C3D4DE;
        }

        .yuan1,
        .yuan2,
        .yuan3 {
            border-radius: 50%;
            opacity: .5;
        }

        .yuan1 {
            animation: yuan1-animation 4s ease-in-out infinite alternate;
        }

        .yuan2 {
            animation: yuan2-animation 5s ease-in-out infinite alternate;
        }

        .yuan3 {
            animation: yuan3-animation 6s ease-in-out infinite alternate;
        }

        // 让yuan1,yuan2,yuan3的高度从0%到100%变化动画
        @keyframes yuan1-animation {
            0% {
                width: 0;
                height: 0;
            }

            100% {
                // width: 24.25*2vw;
                // height: 24.25*2vw;
                width: 42.98*2vw;
                height: 42.98*2vw;
            }
        }

        @keyframes yuan2-animation {
            0% {
                width: 0;
                height: 0;
            }

            100% {
                // width: 33.05*2vw;
                // height: 33.05*2vw;
                width: 42.98*2vw;
                height: 42.98*2vw;
            }
        }

        @keyframes yuan3-animation {
            0% {
                width: 0;
                height: 0;
            }

            100% {
                width: 42.98*2vw;
                height: 42.98*2vw;
            }
        }

    }
}
</style>