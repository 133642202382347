<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="4"></banner>
            <heads></heads>
            <div class="title">News</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    News
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ datas.title1 }}
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'News'" style="flex-shrink: 0;"></leftMenu>
                <div class="right_box">
                    <div class="head_1" style="align-items: flex-start;">
                        <div style="display: flex;" class="head_1box">
                            <div style="margin-right: 70px;"> {{ datas.writer }}
                            </div>
                            <div style="margin-right: 70px;">
                                {{ datas.views }}
                            </div>
                            <div style="display: flex;align-items: center;color: #999999;">
                                <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                {{ datas.create_time | timestampToTime }}
                            </div>
                        </div>
                    </div>
                    <div class="head_1" style="margin-top: 30px;">
                        <div style="display: flex;align-items: center;flex-wrap: wrap;" class="tipsbox"
                            v-if="datas.biaoqian && datas.biaoqian.length != 0">
                            <div class="tips" style="margin:0 10px 10px 0;" v-for=" item  in  datas.biaoqian "
                                v-if="item">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="head_2">
                        {{ datas.title }}
                    </div>
                    <div class="head_3">
                        <div>Follow Us</div>
                        <el-popover placement="top" trigger="hover">
                            <img class="icon" style="width: 150px;" :src="datas1.footer_logo" alt="">
                            <img slot="reference" class="icon" style="margin-top: 10px;" src="@/assets/news/wx.png"
                                alt="">
                        </el-popover>
                        <a target="_blank" href="https://twitter.com/AERSS_office/status/1542597017603608576">
                            <img class="icon" src="@/assets/index/tuite.jpg" alt="">
                        </a>
                    </div>
                    <div class="head_banner_box">
                        <div v-html="datas.content"></div>
                    </div>
                    <div class="head_5">
                        <div style="align-items: start; flex: 2;" @click="toDetail(datas.lastid)">
                            <div class="phone_texts" style="font-size: 20px;color: #666;">Previous</div>
                            <div class="phone_value" style="font-size: 16px;">{{ datas.lasttitle }}</div>
                        </div>
                        <div class="phone_heads5" style="align-items: center;" @click="toNewsList()">
                            <img src="@/assets/news/sige.png" style="width: 56px;" alt="">
                            <div style="margin-top: 17px;color: #005083;font-size: 16px;">Back to list</div>
                        </div>
                        <div style="align-items: flex-end;flex: 2;" @click="toDetail(datas.nextid)">
                            <div class="phone_texts" style="font-size: 20px;color: #666;">Next</div>
                            <div class="phone_value" style="font-size: 16px;">{{ datas.nexttitle }}</div>
                        </div>
                    </div>
                    <div class="list_1" v-if="datas.newspapers">
                        <div style="font-size: 35px;font-weight: bold;color: #005083;" class="phone_title">News，Paper
                        </div>
                        <div class="list_box">
                            <div class="list_item" v-for="( item, key ) in  datas.newspapers " :key="key">
                                <div style="font-size: 14px;">{{ datas.create_time | timestampToTime }}</div>
                                <div class="biaoti"
                                    style="display: flex;align-items: center;justify-content: space-between;font-size: 22px;line-height: 25px;margin-top: 2px;">
                                    <div>
                                        {{ item }}
                                    </div>
                                    <a :href="item" target="_blank">
                                        <img style="width: 49px;margin-left: 180px;" src="@/assets/news/down.png"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text9" v-for=" item  in  datas.donwfile ">
                        <div>
                            <img src="@/assets/committees/icon_pdf.png" style="width: 42px;" alt="">
                            <div class="titles9" style="">{{ item.picname }}</div>
                        </div>
                        <a :href="item.picurl" @click="$download(item.picurl, datas.id, 1)" target="_blank">
                            <img src="@/assets/committees/downs.png" style="width: 49px;cursor: pointer;" alt="">
                        </a>
                    </div>
                    <div class="textarea_box">
                        <el-input type="textarea" placeholder="Please enter the content" v-model="textarea">
                        </el-input>
                        <div class="btn_box seeMore" @click="onPush()">
                            <span>Submit</span>
                            <img src="@/assets/index/right_icon.png" alt="">
                        </div>
                    </div>
                    <div class="liuyan_box">
                        <div class="list_boxs" v-for="( item, key ) in datas.talklist" :key="key">
                            <div style="padding-right: 36px;border-right: 1px solid #eee;text-align: center;">
                                <img style="width: 83px;height: 83px;border-radius: 50%;object-fit: cover;"
                                    :src="item.photo" alt="" @click="toDetails(item)">
                                <div style="color: #005083;margin-top: 10px;font-weight: bold; white-space: nowrap;">
                                    {{
                item.name }}
                                </div>
                            </div>
                            <div style="width: 100%;margin-left: 30px;">
                                <div style="display: flex;align-items: center;color: #999;font-size: 14px;">
                                    <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                    {{ item.create_time | timestampToTime }}
                                </div>
                                <el-popover placement="top" title="" trigger="hover" :content="item.content">
                                    <div slot="reference"
                                        style="font-size: 18px;margin-top: 10px;color: #333333;line-height: 24px;padding-right: 30px;"
                                        class="pps">
                                        {{ item.content }}
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner,
    },
    data() {
        return {
            leftMenuData: [
                {
                    name: 'News',
                    path: '/news'
                }
            ],
            textarea: null,
            datas: {},
            datas1: {}
        }
    },
    methods: {
        tofuPath() {
            this.$router.push({
                path: '/news'
            })
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        getNewsList() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            request({
                url: 'apinewsdetail',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id,
                    type: 1
                }
            }).then(res => {
                this.datas = res.data

                loading.close();
            })
            request({
                url: 'news_detail_num',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id,
                }
            }).then(res => {
            })
        },
        toDetails(item) {
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: item.talk_id
                }
            })
        },
        onPush() {
            request({
                url: 'fujian_talk_add',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    news_id: this.$route.query.id,
                    talk_id: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null,
                    content: this.textarea,
                    type: 1
                }
            }).then(res => {
                if (res.code == 200) {
                    this.textarea = null
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getNewsList()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
            })
        },
        toDetail(id) {
            if (id) {
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$message({
                    message: 'No more',
                    type: 'warning'
                });
            }
            this.getNewsList()
        },
        toNewsList() {
            this.$router.push({
                path: '/news'
            })
        }
    },
    mounted() {
        this.getNewsList()
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.datas1 = res.data
        })
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .body_box {
        margin-top: 0 !important;
    }

    .right_box {
        margin-left: 0px !important;
        padding: 20px !important;
        .head_1 {
            flex-direction: column;
            align-items: flex-start;

            .head_1box {
                width: 100% !important;
                display: flex;
                justify-content: space-between !important;

                div {
                    margin-right: 0px !important;
                }
            }

            .tipsbox {
                width: 100% !important;
                margin-top: 15px !important;
                flex-wrap: wrap !important;

                .tips {
                    margin-right: 10px !important;
                    margin-bottom: 10px !important;
                    font-size: 12px !important;
                }
            }
        }

        .head_2 {
            font-size: 20px !important;
            margin-top: 0px !important;
        }

        .head_3 {
            margin-top: 10px !important;
            justify-content: flex-start !important;
            display: none !important;
        }

        .head_banner_box {
            margin-top: 10px !important;
            overflow: hidden;
            width: 100%;
            padding-bottom: 10px !important;
        }

        .head_5 {
            margin-top: 20px !important;

            .phone_texts {
                font-size: 18px !important;
            }

            .phone_value {
                font-size: 12px !important;
            }

            .phone_heads5 {
                div {
                    font-size: 16px !important;
                }
            }
        }

        .list_1 {
            margin-top: 40px !important;

            .phone_title {
                font-size: 24px !important;
                margin-bottom: 20px !important;
            }

            .list_item {
                padding: 10px 0 !important;

                .biaoti {
                    div {
                        font-size: 14px !important;
                        line-height: normal
                    }

                    img {
                        width: 39px !important;
                        margin-left: 0 !important;
                    }
                }
            }
        }

        .textarea_box {
            margin-top: 20px !important;
            padding: 10px !important;

            .btn_box {
                margin-top: 30px !important;
                font-size: 14px !important;
                display: flex;
                padding: 5px 10px !important;
                justify-content: space-between;

                img {
                    margin-left: 0 !important;
                }
            }
        }

        .liuyan_box {
            margin-top: 20px !important;
            margin-left: 0 !important;

            .list_boxs {
                background-image: none !important;
                padding: 10px !important;
                box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15) !important;
                width: auto !important;
                margin-bottom: 10px !important;
                border-radius: 10px !important;

                .lefts {
                    padding-right: 20px !important;

                    img {
                        background-color: #000000;
                        width: 50px !important;
                        height: 50px !important;
                    }

                    div {
                        margin-top: 5px !important;
                        font-size: 16px !important;
                    }
                }

                .rights {
                    width: auto !important;
                    flex: 1 !important;
                    margin-left: 20px !important;

                    .phone_content {
                        font-size: 15px !important;
                    }
                }
            }
        }
    }
}

.pps {
    // 超出三行省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.content {
    width: 100%;
    background: #fff;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                padding: 20px;
                margin-left: 80px;
                padding-right: 0;
                .head_1 {
                    font-size: 16px;
                    font-weight: 400;
                    color: #666666;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tips {
                        padding: 4px 20px;
                        background: rgba($color: #000000, $alpha: 0.1);
                        border-radius: 20px;
                        color: #333333;
                    }
                }

                .head_2 {
                    font-size: 36px;
                    font-weight: 500;
                    color: #000000;
                    margin-top: 30px;
                }

                .head_3 {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                    justify-content: flex-end;

                    img {
                        width: 24px;
                        margin-left: 29px;
                    }
                }

                .head_banner_box {
                    margin-top: 40px;
                    padding-bottom: 40px;
                    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.1);

                    ::v-deep img {
                        max-width: 100% !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }

                    .news_banner {
                        height: 443px;
                        width: 100%;
                    }

                    .texts {
                        margin-top: 60px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }

                .head_5 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                }

                .head_5>div {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    color: #333333;
                    font-weight: bold;
                    cursor: pointer;

                }

                .list_1 {
                    margin-top: 77px;

                    .list_box {
                        .list_item {
                            padding: 24px 0;
                            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
                        }

                    }
                }

                .text9 {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .titles9 {
                        font-size: 20px;
                        font-weight: bold;
                        color: #005083;
                        margin-left: 20px;
                        width: 85%;
                        // 超出换行文字
                        word-break: break-all;
                    }

                    div {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                        }
                    }
                }

                .textarea_box {
                    margin-top: 70px;
                    display: flex;
                    flex-direction: column;
                    background: #F2F2F2;
                    border: 1px solid #CFCFCF;
                    border-radius: 10px;
                    padding: 30px;

                    ::v-deep .el-textarea__inner {
                        font-family: 'CustomFont1' !important;
                    }

                    .btn_box {
                        width: 110px;
                        margin-top: 95px;
                        padding: 8px 19px;
                        border: 2px solid #005083;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        cursor: pointer;

                        img {
                            width: 23px;
                            margin-left: 30px;
                        }
                    }
                }

                .liuyan_box {
                    margin-top: 35px;
                    margin-left: -30px;

                    .list_boxs {
                        width: 97%;
                        padding: 50px 0 0 50px;
                        background-image: url('@/assets/news/listbg.png');
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        padding-bottom: 70px;
                    }
                }
            }
        }
    }
}
</style>