<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="" style="cursor: pointer;">
                    Personal homepage
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    File directory
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas1='leftMenuData' :title="'Personal homepage'" style="flex-shrink: 0;"></leftMenu>
                <div class="right_box">
                    <div class="submit" @click="toAdd">
                        <span>New addition</span>
                    </div>
                    <el-table :data="tableData" border style="width: 90%"
                        empty-text="There is currently no data available">
                        <el-table-column prop="firstname" label="First level name">
                        </el-table-column>
                        <el-table-column prop="name" label="Secondary name">
                        </el-table-column>
                        <el-table-column fixed="right" label="Operation" width="200">
                            <template slot-scope="scope">
                                <!-- <el-button type="text" size="small">删除</el-button> -->
                                <el-button type="text" size="small" @click="toEdit(scope.row)">Edit</el-button>
                                <el-button type="text" size="small" @click="toDelete(scope.row)">Delete</el-button>
                                <!-- <el-button type="text" size="small" @click="toAddTow(scope.row)">添加二级目录</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="fenye">
                        <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                    </div>
                </div>
            </div>
            <el-dialog :title="propsJson.state == 1 ? 'New addition' : 'Edit'" :visible.sync="propsJson.dialogVisible"
                width="25%">
                <el-form :model="propsJson.form">
                    <el-form-item label="First level name">
                        <el-input style="margin-right: 20px;" v-model="propsJson.form.onelevel"
                            :disabled="propsJson.isAddTow"></el-input>
                    </el-form-item>
                    <el-form-item label="Secondary name">
                        <el-input style="margin-right: 20px;" v-model="propsJson.form.twolevel"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <!-- <el-button type="success" @click="sublit(1)" v-if="propsJson.state != 2">Keep going</el-button> -->
                    <div class="submit" @click="sublit(1)" v-if="propsJson.state != 2">
                        <span>Keep going</span>
                    </div>
                    <div class="submit1" @click="sublit(2)">
                        <span>Preserve</span>
                    </div>
                    <!-- <el-button type="primary" @click="sublit(2)">Preserve</el-button> -->
                </span>
            </el-dialog>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import pagination from '@/components/pagination.vue'

import request from '@/utils/request'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination,
    },
    data() {
        return {
            leftMenuData: [{
                name: 'Profile',
                url: '/memberm', path: '/memberm'
            },
            {
                name: 'Upload',
                url: '/memberm3', path: '/memberm3'
            },
            {
                name: 'Material Library',
                url: '/memberm2', path: '/memberm2'
            },
            {
                name: 'File directory',
                url: '/contentsPage', path: '/contentsPage'
            },
            ],
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            tableData: [],
            propsJson: {
                dialogVisible: false,
                state: 1,// 1新增 2编辑
                isAddTow: false,
                form: {
                    onelevel: null,
                    twolevel: null,
                    row: null,
                }
            },
        }
    },
    methods: {
        getDatas() {
            request({
                url: 'get_fenlei_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                }
            }).then(res => {
                this.tableData = res.data
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
        toAdd() {
            this.propsJson.isAddTow = false
            this.propsJson.dialogVisible = true
            this.propsJson.state = 1
        },
        toAddTow(row) {
            this.propsJson.form.onelevel = row.firstname
            this.propsJson.form.row = row
            this.propsJson.isAddTow = true
            this.propsJson.dialogVisible = true
            this.propsJson.state = 1
        },
        toEdit(row) {
            this.propsJson.dialogVisible = true
            this.propsJson.state = 2
            this.propsJson.isAddTow = false
            this.propsJson.form.onelevel = row.firstname
            this.propsJson.form.twolevel = row.name
            this.propsJson.form.row = row
        },
        toDelete(row) {
            this.$confirm('Are you sure you want to delete it?', 'Prompt', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                request({
                    url: 'del_menu',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        cid: row.cid,
                        id: row.id,
                        user_id: row.user_id
                    }
                }).then(res => {
                    this.getDatas()
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
        },
        // 保存后操作
        baocun(e, num) {
            // 保存并继续
            if (e == 1) {
                if (num == 2) {
                    this.propsJson.form.twolevel = null
                } else {
                    this.propsJson.form.onelevel = null
                    this.propsJson.form.twolevel = null
                }
            } else {
                // 跳转发布页面
                this.propsJson.dialogVisible = false
                this.$router.push({
                    path: '/memberm3',
                })
            }
        },
        sublit(e) {
            if (this.propsJson.form.onelevel == null || this.propsJson.form.twolevel == null) {
                this.$message.error('Please fill in the folder name')
                return
            }
            // 新增
            if (this.propsJson.state == 1) {
                // 添加二级目录
                if (this.propsJson.isAddTow) {
                    request({
                        url: 'add_fenlei_second',
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                            cid: this.propsJson.form.row.cid,
                            secondname: this.propsJson.form.twolevel
                        }
                    }).then(res => {
                        this.getDatas()
                        this.baocun(e, 2)
                    })
                } else {
                    // 添加一级目录
                    request({
                        url: 'add_fenlei',
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                            firstname: this.propsJson.form.onelevel,
                            secondname: this.propsJson.form.twolevel
                        }
                    }).then(res => {
                        this.getDatas()
                        this.baocun(e)
                    })
                }

            } else {
                // 编辑
                request({
                    url: 'get_update_data',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                        firstname: this.propsJson.form.onelevel,
                        secondname: this.propsJson.form.twolevel,
                        id: this.propsJson.form.row.id,
                        cid: this.propsJson.form.row.cid
                    }
                }).then(res => {
                    this.getDatas()
                    this.baocun(e)
                })
            }
        }
    },
    mounted() {
        this.getDatas()
    },
    watch: {
        'propsJson.dialogVisible': function (val) {
            if (!val) {
                this.propsJson.form.onelevel = null
                this.propsJson.form.twolevel = null
                this.propsJson.form.row = null
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .cell{
    font-size: 16px !important;
}
::v-deep .el-button--text {
    color: #005083;
    font-family: 'CustomFont' !important;
    font-size: 16px !important;
    &:hover {
        color: #005083;
    }
}

.submit {
    padding: 0 19px;
    margin-bottom: 10px;
    height: 38px;
    border: 2px solid #005083;
    background-color: #005083;
    color: #fff;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    div {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
    }
}

.submit1 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    padding: 0 19px;
    margin-bottom: 10px;
    height: 38px;
    border: 2px solid #fff;
    background-color: #fff;
    color: #005083;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    div {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
    }
}

.content {
    width: 100%;
    background: #F7FAFB;

    .box {
        position: relative;
        overflow: hidden;
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-top: 110px;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;


            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .icons {
            cursor: pointer;
        }

        .body_box {
            margin-top: 50px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .right_box {
                flex: 1;
                margin-left: 60px;

                .fenye {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                }
            }
        }
    }

}
</style>