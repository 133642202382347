<template>
    <div class="content">
        <div class="box">
            <banner :types="2"></banner>
            <heads></heads>
            <div class="title">Organization</div>
        </div>
        <div class="box1">
            <div class="icon_box" v-if="objs">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;" @click="$tofuPath(objs)">
                    {{ objs.futitle }}
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ objs.title }}
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Organization'"></leftMenu>
                <div class="right_box">
                    <div v-html="datas.content"></div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import leftMenu from '@/components/leftMenu.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner
    },
    data() {
        return {
            objs: null,

            leftMenuData: [
                {
                    name: 'Constitution',
                    path: '/Constitution'
                }, {
                    name: 'Structure',
                    path: '/Introduction'
                },
                {
                    name: 'AERSS Leadership',
                    path: '/Leadership'
                },
                {
                    name: 'Council',
                    path: '/Council'
                },
                {
                    name: 'Scientific Steering Committee',
                    path: '/Scientific'
                },

                {
                    name: 'Working Groups',
                    path: '/WorkingGroups'
                },
                {
                    name: 'ECPC',
                    path: '/Ecpc'
                },
                {
                    name: 'Secretariat',
                    path: '/Secretariat'
                },
                {
                    name: 'Milestone',
                    path: '/Milestone'
                },
                {
                    name: 'Contact Us',
                    path: '/Contact'
                }
            ],
            datas: {}
        }
    },
    methods: {
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/Introduction'
            })
        },
        getDatas() {
            request({
                url: 'apiabout',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    type: 6
                }
            }).then(res => {
                this.datas = res.data
                this.datas.content = this.datas.content.replace(/<img/g, '<img style="width:100%"')
            })
        }
    },
    mounted() {
        this.getDatas()
        this.objs = this.$route.query

    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .body_box {
        margin: 0 10px !important;

        .right_box {
            margin-left: 0;
        }
    }
}

.content {
    width: 100%;
    background: #fff;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 110px;
                overflow: hidden;

                ::v-deep img {
                    max-width: 100% !important;

                    &:hover {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
</style>