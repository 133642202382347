import { MenuItem } from 'element-ui'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    MenuItem: {}
  },
  getters: {
  },
  mutations: {
    SET_MENU(state, items) {
      state.MenuItem = items
      // 本地存储
      localStorage.setItem('MenuItem', JSON.stringify(items))
    }
  },
  actions: {
    setMenus({ commit }, menu) {
      commit('SET_MENU', menu) 
    }
  },
  modules: {
  }
})
