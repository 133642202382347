<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Personal homepage
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Profile
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas1='leftMenuData' :title="'Personal homepage'"></leftMenu>
                <div class="right_box">
                    <div class="right_heads" v-if="datas">
                        <div style="height: 100%;display: flex;align-items: center;justify-content: center;">
                            <div
                                style="cursor: pointer;width: 170px;height: 170px;background-color: #005083;border-radius: 100%;margin: 60px;display: flex;align-items: center;justify-content: center;">
                                <label for="fileInput" style="cursor: pointer;">
                                    <div style="margin: 0 30px; font-size: 14px; color: #fff; text-align: center;"
                                        v-if="!datas.photo.value">
                                        Click to change the picture
                                    </div>
                                    <img v-else :src="datas.photo.value" alt=""
                                        style="width: 176px; height: 176px; border-radius: 100%;object-fit: cover;">
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                        <div class="name_box">
                            <div>
                                <!-- datas.first_name.value -->
                                <!-- <span style="font-size: 32px;font-weight: bold;margin-right: 20px;">{{
                datas.fullname.value
            }}</span> -->
                                <span style="font-size: 32px;font-weight: bold;margin-right: 20px;">
                                    {{ datas.first_name.value }} {{ datas.last_name.value }}
                                </span>
                                {{ datas.foundingmember.value }}
                            </div>
                            <div
                                style="margin-top: 16px;color: #777777;font-size: 14px;display: flex;align-items: center;">
                                <input type="text" v-model="datas.doctor.value" style="flex: 1;font-size: 14px;"
                                    ref="doctor" :disabled="datas.doctor.disabled" @blur="handleBlur(datas.doctor)"
                                    class="add_input">
                                <img class="icons" style="width: 21px;height: 21px;margin-left: 31px;"
                                    src="@/assets/memberm/icon.png" alt="" @click="getUpdata(datas.doctor)">
                            </div>
                            <div style="display: flex;margin-top: 40px;">
                                <div style="font-weight: bold;margin-right: 63px;">
                                    <div>Credits</div>
                                    <div style="color: #005083;font-size: 32px;text-align: center;">{{
                                        datas.point.value }}</div>
                                </div>
                                <div style="font-weight: bold;margin-right: 63px;">
                                    <div>Resources</div>
                                    <div style="color: #005083;font-size: 32px;text-align: center;">{{
                                        datas.article_num.value }}</div>
                                </div>
                                <div style="font-weight: bold;">
                                    <div style="font-size: 18px;color: #005083">Research interests</div>
                                    <div style="font-size: 18px;width: 250px;">{{ datas.research.value }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right_content" v-if="datas">
                        <div>
                            <div
                                style="font-weight: bold;font-size: 26px;padding-bottom: 50px;border-bottom: 1px solid #eee;">
                                Profile</div>
                            <div style="display: flex;font-size: 20px;">
                                <div class="items">
                                    <div>
                                        <div>First Name</div>
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.first_name.value">
                                            <input type="text" slot="reference" v-model="datas.first_name.value"
                                                placeholder="" ref="first_name" :disabled="datas.first_name.disabled"
                                                @blur="handleBlur(datas.first_name)" class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.first_name)">
                                </div>
                                <div class="items">
                                    <div>
                                        <div>Last Name</div>
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.last_name.value">
                                            <input type="text" slot="reference" v-model="datas.last_name.value"
                                                placeholder="" ref="last_name" :disabled="datas.last_name.disabled"
                                                @blur="handleBlur(datas.last_name)" class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.last_name)">
                                </div>
                                <div class="items" style="margin-right: 0;">
                                    <div>
                                        <div>Gender</div>
                                        <el-select v-if="!datas.gender.disabled" style="border: none !important;"
                                            v-model="datas.gender.value" @change="handleBlur(datas.gender)"
                                            :disabled="datas.gender.disabled" ref="gender" class="add_input add_input1">
                                            <el-option v-for="item in options1" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <input v-else type="text" :value="idToName(datas.gender.value, options1)"
                                            placeholder="" ref="gender" disabled class="add_input add_input1">
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.gender)">
                                </div>
                            </div>
                            <div style="display: flex;font-size: 20px;">
                                <div class="items">
                                    <div>
                                        <div>Title</div>
                                        <el-select placeholder="" v-model="datas.title.value"
                                            style="border: none !important;" @blur="handleBlur(datas.title)"
                                            @change="handleBlur(datas.title)" :disabled="datas.title.disabled"
                                            ref="title" class="add_input add_input1">
                                            <el-option v-for="item in options2" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <!-- <input type="text" :value="idToName(datas.title.value, options2)" placeholder=""
                                            ref="gender" disabled class="add_input add_input1"> -->
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.title)">
                                </div>
                                <div class="items">
                                    <div>
                                        <div>Affiliation</div>
                                        <!-- <input type="text" v-model="datas.institution.value" placeholder=""
                                            ref="institution" :disabled="datas.institution.disabled"
                                            @blur="handleBlur(datas.institution)" class="add_input add_input1"> -->
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.institution.value">
                                            <input type="text" slot="reference" v-model="datas.institution.value"
                                                placeholder="" ref="institution" :disabled="datas.institution.disabled"
                                                @blur="handleBlur(datas.institution)" class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.institution)">
                                </div>
                                <div class="items" style="margin-right: 0;">
                                    <div>
                                        <div>Region</div>
                                        <el-select v-if="!datas.region.disabled"
                                            style="border: none !important;width: 180px;" v-model="datas.region.value"
                                            filterable remote reserve-keyword :remote-method="remoteMethod"
                                            :disabled="datas.region.disabled" :loading="loadings"
                                            class="add_input add_input1" @change="handleBlur(datas.region)"
                                            @blur="handleBlur(datas.region)">
                                            <el-option v-for="item in options3" :key="item.name_cn"
                                                :label="item.name_cn" :value="item.name_cn">
                                            </el-option>
                                        </el-select>
                                        <input v-else type="text" v-model="datas.region.value" placeholder=""
                                            ref="region" disabled class="add_input add_input1">
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.region)">
                                </div>
                            </div>
                            <div style="display: flex;font-size: 20px;">
                                <div class="items">
                                    <div>
                                        <div>E-mail</div>
                                        <!-- <input type="text" v-model="datas.email.value" placeholder="" ref="email"
                                            :disabled="datas.email.disabled" @blur="handleBlur(datas.email)"
                                            class="add_input add_input1"> -->
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.email.value">
                                            <input type="text" slot="reference" v-model="datas.email.value"
                                                placeholder="" ref="email" :disabled="datas.email.disabled"
                                                @blur="handleBlur(datas.email)" class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                    <!-- <img class="icons" style="width: 16px;height: 16px;"
                                        src="@/assets/memberm/icon.png" alt="" @click="getUpdata(datas.email)"> -->
                                </div>
                                <div class="items">
                                    <div>
                                        <div>Member Type</div>
                                        <!-- <input type="text" v-model="datas.member.value" placeholder="" ref="member"
                                            :disabled="datas.member.disabled" @blur="handleBlur(datas.member)"
                                            class="add_input add_input1"> -->
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.member.value">
                                            <input type="text" slot="reference" v-model="datas.member.value"
                                                placeholder="" ref="member" :disabled="datas.member.disabled"
                                                @blur="handleBlur(datas.member)" class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                </div>
                                <div class="items" style="margin-right: 0;">
                                    <div>
                                        <div>Research interests</div>
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.research.value">
                                            <input type="text" style="width: 180px;" v-model="datas.research.value"
                                                placeholder="" ref="research" :disabled="datas.research.disabled"
                                                slot="reference" @blur="handleBlur(datas.research)"
                                                class="add_input add_input1">
                                        </el-popover>

                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.research)">
                                </div>
                            </div>
                            <div style="display: flex;font-size: 20px;">
                                <div class="items">
                                    <div>
                                        <div>Tel</div>
                                        <div style="display: flex;align-self: center;">
                                            <div class="add_input add_input1"
                                                style="line-height: 30px;flex: none !important;border: none !important;margin-right: 2px;">
                                                +</div>
                                            <input type="text" style="width: 40px !important;margin-right: 20px;"
                                                v-model="datas.country_number.value" placeholder="" ref="tel"
                                                :disabled="datas.tel.disabled" @blur="handleBlur(datas.country_number)"
                                                class="add_input add_input1 add_input2">
                                            <input type="text" style="width: 200px !important;"
                                                v-model="datas.tel.value" placeholder="" ref="tel"
                                                :disabled="datas.tel.disabled" @blur="handleBlur(datas.tel)"
                                                class="add_input add_input1">
                                        </div>
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.tel)">
                                </div>
                                <div class="items" style="margin: 0;">
                                    <div>
                                        <div>Address</div>
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="datas.address.value">
                                            <input style="width: 310px !important;" type="text" slot="reference"
                                                v-model="datas.address.value" placeholder="" ref="address"
                                                :disabled="datas.address.disabled" @blur="handleBlur(datas.address)"
                                                class="add_input add_input1">
                                        </el-popover>
                                    </div>
                                    <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png"
                                        alt="" @click="getUpdata(datas.address)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="phone_right_box right_box" style="display: none;">
                    <div class="right_heads" style="display: block;" v-if="datas">
                        <div style="display: flex;align-items: center;">
                            <div class="touxiangbox"
                                style="cursor: pointer;width: 170px;height: 170px;background-color: #005083;border-radius: 100%;margin: 60px;display: flex;align-items: center;justify-content: center;">
                                <label for="fileInput" style="cursor: pointer;">
                                    <div style="font-size: 14px; color: #fff; text-align: center;"
                                        v-if="!datas.photo.value">
                                        Click to change the picture
                                    </div>
                                    <img v-else :src="datas.photo.value" alt=""
                                        style="width: 170px; height: 170px; border-radius: 100%;object-fit: cover;">
                                </label>

                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @blur="handleFileUpload" />
                            </div>
                            <div
                                style="display: flex;margin-top: 0px;justify-content: space-between;flex: 1;margin-left: 10px;">
                                <div
                                    style="font-weight: bold;flex: 1;text-align: center;display: flex;flex-direction: column;height: 100%;">
                                    <div style="font-size: 12px;">Credits</div>
                                    <div style="color: #005083;font-size: 12px;">{{ datas.member_points.value }}</div>
                                </div>
                                <div
                                    style="font-weight: bold;flex: 1;text-align: center;display: flex;flex-direction: column;height: 100%;">
                                    <div style="font-size: 12px;">Resources</div>
                                    <div style="color: #005083;font-size: 12px;">{{ datas.article_num.value }}</div>
                                </div>
                                <div
                                    style="font-weight: bold;flex: 1;text-align: center;display: flex;flex-direction: column;height: 100%;">
                                    <div style="font-size: 12px;color: #005083">Research interests</div>
                                    <div style="font-size: 12px;">{{ datas.research.value }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="phone_name_box">
                            <div>
                                <span style="font-size: 14px;font-weight: bold;margin-right: 5px;">{{
                                    datas.fullname.value
                                    }}</span>
                                {{ datas.foundingmember.value }}
                            </div>
                            <div
                                style="color: #777777;font-size: 14px;display: flex;align-items: center;margin-top: 10px !important;">
                                <input type="text" v-model="datas.doctor.value" style="flex: 1;font-size: 14px;"
                                    ref="doctor" :disabled="datas.doctor.disabled" @blur="handleBlur(datas.doctor)">
                                <img class="icons"
                                    style="width: 15px;height: 15px;margin-left: 10px;margin-right: 20px;"
                                    src="@/assets/memberm/icon.png" alt="" @click="getUpdata(datas.doctor)">
                            </div>

                        </div>
                    </div>
                    <div class="right_content" v-if="datas">
                        <div
                            style="font-weight: bold;font-size: 16px;padding-bottom: 10px;border-bottom: 1px solid #eee;width: 100%;">
                            Profile</div>
                        <div class="items">
                            <div>
                                <div>First Name</div>
                                <input type="text" v-model="datas.first_name.value" placeholder="" ref="first_name"
                                    :disabled="datas.first_name.disabled" @blur="handleBlur(datas.first_name)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.first_name)">
                        </div>
                        <div class="items">
                            <div>
                                <div>Last Name</div>
                                <input type="text" v-model="datas.last_name.value" placeholder="" ref="last_name"
                                    :disabled="datas.last_name.disabled" @blur="handleBlur(datas.last_name)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.last_name)">
                        </div>
                        <div class="items" style="margin-right: 0;">
                            <div>
                                <div>Gender</div>
                                <el-select style="border: none !important;" v-model="datas.gender.value"
                                    @change="handleBlur(datas.gender)" :disabled="datas.gender.disabled" ref="gender"
                                    class="add_input add_input1">
                                    <el-option v-for="item in options1" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.gender)">
                        </div>
                        <div class="items">
                            <div>
                                <div>Title</div>
                                <el-select style="border: none !important;" v-model="datas.title.value"
                                    @change="handleBlur(datas.title)" :disabled="datas.title.disabled" ref="title"
                                    class="add_input add_input1">
                                    <el-option v-for="item in options2" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.title)">
                        </div>
                        <div class="items">
                            <div>
                                <div>Affiliation</div>
                                <input type="text" v-model="datas.institution.value" placeholder="" ref="institution"
                                    :disabled="datas.institution.disabled" @blur="handleBlur(datas.institution)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.institution)">
                        </div>
                        <div class="items" style="margin-right: 0;">
                            <div>
                                <div>Region</div>
                                <el-select style="border: none !important;" v-model="datas.region.value" filterable
                                    remote reserve-keyword :remote-method="remoteMethod"
                                    :disabled="datas.region.disabled" :loading="loadings" class="add_input add_input1"
                                    @change="handleBlur(datas.region)">
                                    <el-option v-for="item in options3" :key="item.name_cn" :label="item.name_cn"
                                        :value="item.name_cn">
                                    </el-option>
                                </el-select>
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.region)">
                        </div>
                        <div class="items">
                            <div>
                                <div>E-mail</div>
                                <input type="text" v-model="datas.email.value" placeholder="" ref="email"
                                    :disabled="datas.email.disabled" @blur="handleBlur(datas.email)"
                                    class="add_input add_input1">
                            </div>
                            <!-- <img class="icons" style="width: 16px;height: 16px;"
                                src="@/assets/memberm/icon.png" alt="" @click="getUpdata(datas.email)"> -->
                        </div>
                        <div class="items">
                            <div>
                                <div>Member</div>
                                <input type="text" v-model="datas.member.value" placeholder="" ref="member"
                                    :disabled="datas.member.disabled" @blur="handleBlur(datas.member)"
                                    class="add_input add_input1">
                            </div>
                            <!-- <img class="icons" style="width: 16px;height: 16px;"
                                src="@/assets/memberm/icon.png" alt="" @click="getUpdata(datas.member)"> -->
                        </div>
                        <div class="items" style="margin-right: 0;">
                            <div>
                                <div>Tel</div>
                                <input type="text" v-model="datas.tel.value" placeholder="" ref="tel"
                                    :disabled="datas.tel.disabled" @blur="handleBlur(datas.tel)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.tel)">
                        </div>
                        <div class="items">
                            <div>
                                <div>Research interests</div>
                                <input type="text" v-model="datas.research.value" placeholder="" ref="research"
                                    :disabled="datas.research.disabled" @blur="handleBlur(datas.research)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.research)">
                        </div>
                        <div class="items" style="flex: 2;margin-right: 0;">
                            <div>
                                <div>Address</div>
                                <input type="text" v-model="datas.address.value" placeholder="" ref="address"
                                    :disabled="datas.address.disabled" @blur="handleBlur(datas.address)"
                                    class="add_input add_input1">
                            </div>
                            <img class="icons" style="width: 16px;height: 16px;" src="@/assets/memberm/icon.png" alt=""
                                @click="getUpdata(datas.address)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
export default {
    components: {
        heads,
        foots,
        leftMenu
    },
    data() {
        return {
            leftMenuData: [{
                name: 'Profile',
                url: '/memberm', path: '/memberm'
            },
            {
                name: 'Upload',
                url: '/memberm3', path: '/memberm3'
            },
            {
                name: 'Material Library',
                url: '/memberm2', path: '/memberm2'
            },
            {
                name: 'File directory',
                url: '/contentsPage', path: '/contentsPage'
            },
            ],
            datas: null,
            options1: [],
            options2: [],
            options3: [],
            loadings: false,
        }
    },
    mounted() {
        this.getJianjie()
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            if (res.code == 200) {
                this.options1 = res.data.sex
                this.options2 = res.data.position
            }
        })
        var thi = this
        document.documentElement.addEventListener('click', function (event) {
            // 处理点击事件
            if (event.target.tagName === 'INPUT' || event.target.className === 'icons') {
                return
            } else {
                for (let key in thi.datas) {
                    thi.datas[key].disabled = true
                }
            }
        });
    },
    methods: {
        idToName(id, arr) {
            if (!id) return ''
            let name = ''
            arr.forEach(item => {
                if (item.id == id) {
                    name = item.name
                }
            })
            return name
        },
        remoteMethod(query) {
            if (query !== '') {
                this.loadings = true;
                request({
                    url: 'find_country_data',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        name_cn: query
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.loadings = false;
                        this.options3 = res.data
                    }
                })
            } else {
                this.options3 = [];
            }
        },
        toHome() {
            this.$router.push('/')
        },
        getJianjie() {
            request({
                url: 'apimemberinfo',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    email: JSON.parse(localStorage.getItem('userinfo')).data.email
                }
            }).then(res => {
                let datas = JSON.parse(localStorage.getItem('userinfo'))
                datas.data = JSON.parse(JSON.stringify(res.data))
                localStorage.setItem('userinfo', JSON.stringify(datas))
                this.datas = res.data
                for (let key in this.datas) {
                    this.datas[key] = {
                        key: key,
                        value: this.datas[key],
                        disabled: true
                    }
                }
            })
        },
        getUpdata(props) {
            // 关闭其他
            for (let key in this.datas) {
                if (key != props.key) {
                    this.datas[key].disabled = true
                }
            }
            if (props.disabled) {
                props.disabled = false
                this.$nextTick(() => {
                    this.$refs[props.key].focus()
                })
            }

        },
        handleBlur(props) {
            let data = {
                email: JSON.parse(localStorage.getItem('userinfo')).data.email,
                key: props.key,
                value: props.value
            }
            // if(data.key == 'tel'){
            //     if(!(/^1[3456789]\d{9}$/.test(data.value))){
            //         this.$message.error('Please enter the correct phone number')
            //         return
            //     }
            // }
            request({
                url: 'apimemberinfoupdate',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data,
            }).then(res => {
                props.disabled = true;
                if (res.code == 200) {
                    if (props.key == "email") {
                        let datas = JSON.parse(localStorage.getItem('userinfo'))
                        datas.data.email = props.value
                        localStorage.setItem('userinfo', JSON.stringify(datas))
                    }
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
                if (props.key == "first_name" || props.key == "last_name" || props.key == "photo") {
                    request({
                        url: 'apimemberinfo',
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            email: JSON.parse(localStorage.getItem('userinfo')).data.email
                        }
                    }).then(res => {
                        let datas = JSON.parse(localStorage.getItem('userinfo'))
                        datas.data = JSON.parse(JSON.stringify(res.data))
                        localStorage.setItem('userinfo', JSON.stringify(datas))
                        window.location.reload()
                    })
                } else {
                    this.getJianjie()
                }
            })
        },
        handleFileUpload(event) {
            this.file = event.target.files[0]; // 获取用户选择的文件
            if (!this.file) {
                return
            }
            request({
                url: 'upload',
                method: 'post',
                data: {
                    file: this.file
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                let datas = {
                    email: JSON.parse(localStorage.getItem('userinfo')).data.email,
                    key: 'photo',
                    value: res.data.picurl
                }
                this.handleBlur(datas)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .box1 {
        margin-top: 30px !important;
        transform: scale(1) !important;
        padding: 0 !important;
    }

    .body_box {
        margin: 10px !important;

        .right_box {
            display: none;
            transform: scale(1) !important;
            margin-top: 0px !important;
        }

        .phone_right_box {
            display: block !important;

            .right_heads {
                background: none;
                background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
                border-radius: 20px;
                display: flex;
                height: auto !important;
                width: auto !important;
                padding: 10px !important;

                .touxiangbox {
                    width: 60px !important;
                    height: 60px !important;
                    padding: 0 !important;
                    margin: 0 !important;

                    img {
                        width: 60px !important;
                        height: 60px !important;
                    }
                }

                .phone_name_box {
                    flex: 1;
                    margin: 0px !important;
                    font-size: 16px;
                    color: #222222;
                    font-weight: 400;
                    margin-top: 20px !important;
                }
            }

            .right_content {
                background: none;
                background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                height: auto !important;
                width: auto !important;
                padding: 10px !important;

                .items {
                    padding: 10px 0 !important;
                    margin-right: 0 !important;

                    .add_input1 {
                        font-size: 14px !important;
                        padding: 0 !important;
                        width: 80%;
                    }
                }

                .items>div {
                    flex: 1;
                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F7FAFB;

    ::v-deep input {
        line-height: normal !important;
        height: auto !important;
        outline: none;
    }

    ::v-deep input:focus {
        outline: none !important;
        border: 1px solid #aaa !important;
    }

    ::v-deep input:disabled {
        background: none !important;
        padding: 0 !important;
        border: 1px solid rgba($color: #000000, $alpha: 0) !important;
        // overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .add_input {
        border: 1px solid #aaa !important;
        font-size: 20px !important;
        background: none !important;
        padding: 0 4px !important;
        margin-top: 10px !important;
        height: 30px !important;
    }

    .add_input1 {
        flex: 1;
        font-size: 20px !important;
        color: #005083 !important;
        outline: none !important;

        ::v-deep input:focus {
            outline: none !important;
            border: 1px solid #aaa !important;
            padding: 0 4px !important;
        }

        ::v-deep input {
            height: 30px !important;
            font-size: 20px !important;
            color: #005083 !important;
            outline: none !important;
            padding: 0 !important;
            border: 1px solid #aaa !important;
            padding: 0 4px !important;
        }

        ::v-deep input:disabled {
            background: none !important;
            padding: 0 !important;
            border: 1px solid rgba($color: #000000, $alpha: 0) !important;
        }
    }



    ::v-deep .el-input__suffix {
        display: none;
    }

    .box {
        position: relative;
        overflow: hidden;
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-top: 110px;
        margin-bottom: 30px;
        padding-left: 80px;

        .icon_box {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .icons {
            cursor: pointer;
        }

        .body_box {
            margin-top: 50px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                transform: scale(.8);
                margin-top: -110px;

                .right_heads {
                    width: 100%;
                    height: 316px;
                    background-image: url("@/assets/memberm/bg1s.png");
                    background-size: 100% 100%;
                    overflow: hidden;
                    display: flex;

                    .name_box {
                        font-size: 18px;
                        margin-top: 64px;
                    }
                }

                .right_content {
                    margin-top: 20px;
                    background-image: url("@/assets/memberm/bg2s.png");
                    background-size: 100% 100%;
                    padding: 60px;

                    .items {
                        flex: 1;
                        overflow: hidden;
                        padding: 48px 0px 20px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #ddd;
                        margin-right: 40px;
                    }

                    .items>div {
                        width: 180px;
                    }
                }
            }
        }
    }

}
</style>