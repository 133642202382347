<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="4"></banner>
            <heads></heads>
        </div>
        <div class="box1">
            <div class="icon_box" v-if="objs">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{objs.futitle}}
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{objs.title}}
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData'></leftMenu>
                <div class="right_box">
                    <div class="tiaomu_box">
                        <div class="tiaomu_item" @click="toDetail(item.id)" v-for="item in newsData">
                            <img class="tiapmu_item_img" style="object-fit: cover;" :src="item.photo" alt="">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="color: #005083;font-size: 16px;margin-right: 40px;" v-if="item.writer">
                                        {{ item.writer }}
                                    </div>
                                    <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                        <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                            alt="">
                                        {{ item.create_time | timestampToTime }}
                                    </div>
                                </div>
                                <el-popover placement="top" title="" width="400" trigger="hover" :content="item.title">
                                    <div slot="reference"
                                        style="margin-top: 16px;font-weight: 500;color: #000000;font-size: 20px;"
                                        class="content_title">
                                        {{ item.title }}
                                    </div>
                                </el-popover>
                                <el-popover placement="top" title="" width="400" trigger="hover"
                                    :content="item.kkeyword">
                                    <div slot="reference"
                                        style="margin-top: 24px;font-weight: 400;color: #666666;font-size: 16px;"
                                        class="content_title">
                                        {{ item.kkeyword }}
                                    </div>
                                </el-popover>
                                <div class="tips_box">
                                    <div v-for="items in item.biaoqian">{{ items }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fenye">
                            <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                        </div>
                    </div>
                </div>
                <div class="phone_right_box" style="display: none;">
                    <div class="lunbo_box" v-if="lunboData && lunboData.length != 0"
                        style="padding-bottom: 0px !important;">
                        <!-- <el-carousel arrow="always" height="320px"
                            style="width: 100%;margin: auto; box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15);border-radius: 20px;overflow: hidden;">
                            <el-carousel-item v-for="(item, key) in lunboData" :key="key">
                                <div class="item phonelunbo" style="box-shadow:none !important"
                                    @click="toDetail(item.id)">
                                    <div class="textneirong">
                                        <div class="head_box" style="height: 10px;">
                                            <div style="color: #005083;font-size: 14px;">{{ item.writer }}</div>
                                            <div
                                                style="display: flex;align-items: center;color: #999999;font-size: 12px;">
                                                <img style="width: 14px;margin-right: 10px;"
                                                    src="@/assets/news/time.png" alt="">
                                                {{ item.create_time | timestampToTime }}
                                            </div>
                                        </div>
                                        <div class="content_item chaochu">
                                            {{ item.title }}
                                        </div>
                                        <div class="text_box chaochu">
                                            {{ item.kkeyword }}
                                        </div>
                                    </div>
                                    <img class="right_img" style="object-fit: cover;height: 180px;" :src="item.photo"
                                        alt="">
                                </div>
                            </el-carousel-item>
                        </el-carousel> -->
                        <el-carousel :autoplay="false" :style="{ height: banner1herght + 'px' }"
                            style=" box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15);border-radius: 20px;overflow: hidden;">
                            <el-carousel-item v-for="(item, key) in lunboData" :key="key"
                                :style="{ height: banner1herght + 'px' }">
                                <div class="item phonelunbo" style="box-shadow:none !important"
                                    @click="toDetail(item.id)">
                                    <div class="textneirong">
                                        <div class="head_box" style="height: 10px;">
                                            <div style="color: #005083;font-size: 14px;">{{ item.writer }}</div>
                                            <div
                                                style="display: flex;align-items: center;color: #999999;font-size: 12px;">
                                                <img style="width: 14px;margin-right: 10px;"
                                                    src="@/assets/news/time.png" alt="">
                                                {{ item.create_time | timestampToTime }}
                                            </div>
                                        </div>
                                        <div class="content_item chaochu">
                                            {{ item.title }}
                                        </div>
                                        <div class="text_box chaochu">
                                            {{ item.kkeyword }}
                                        </div>
                                    </div>
                                    <img class="right_img" @load="setBannerHeight" :src="item.photo" alt="">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="lunbo_box">
                        <div class="item" v-for="(item, key) in newsData" @click="toDetail(item.id)">
                            <div class="textneirong">
                                <div class="head_box">
                                    <div style="color: #005083;font-size: 14px;">{{ item.writer }}</div>
                                    <div style="display: flex;align-items: center;color: #999999;font-size: 12px;">
                                        <img style="width: 14px;margin-right: 10px;" src="@/assets/news/time.png"
                                            alt="">
                                        {{ item.create_time | timestampToTime }}
                                    </div>
                                </div>
                                <div class="tips_box">
                                    <div v-for="items in item.biaoqian">{{ items }}</div>
                                </div>
                                <div class="content_item">
                                    {{ item.title }}
                                </div>
                                <div class="text_box">
                                    {{ item.kkeyword }}
                                </div>
                            </div>
                            <img class="right_img" style="object-fit: cover;" :src="item.photo" alt="">
                        </div>
                    </div>
                    <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request';
import leftMenu from '@/components/leftMenu.vue'
import pagination from '@/components/pagination.vue'
import banner from '@/components/banner.vue';

export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination,
        banner
    },
    data() {
        return {
            carouselHeight: '363px',
            leftMenuData: [{
                name: 'News',
                path: '/news'
            },
            ],
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            lunboData: [],
            lunboActive: 0,
            newsData: [],
            banner1herght: 0,
            objs: null
        }
    },
    mounted() {
        this.getDatas()
        this.startAutoPlay()
        this.objs = this.$route.query
    },
    watch: {
        '$route.query': {
            handler: function (val, oldval) {
                this.objs = val
                this.getDatas()
            },
            deep: true
        }
    },
    methods: {
        setBannerHeight() {
            this.banner1herght = document.querySelector('.phonelunbo').offsetHeight;
        },
        stopAutoPlay() {
            clearInterval(this.timer); // 清除定时器，停止自动轮播
        },
        startAutoPlay() {
            this.timer = setInterval(() => { // 重新设置定时器，继续自动轮播
                if (this.lunboActive < this.lunboData.length - 1) {
                    this.lunboActive++
                } else {
                    this.lunboActive = 0
                }
            }, 2500);
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        getDatas() {
            request({
                url: 'apinewslist',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    page: this.pageData.current_page,
                    type: this.$route.query.type
                }
            }).then(res => {
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.newsData = res.data.data
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
        toDetail(id) {
            this.$router.push({
                path: `/newListDetail?id=${id}&&type=${this.$route.query.type}&&futitle=${this.$route.query.futitle}&&title=${this.$route.query.title}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
    height: 100% !important;
}

@media screen and (max-width: 1025px) {
    .chaochu {
        // 文字超出2行显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100% !important;
        height: 50px !important;
    }

    .right_box {
        display: none !important;
    }

    .body_box {
        margin-top: 0px !important;
    }

    .phone_right_box {
        display: flex !important;
        flex-direction: column !important;

        .lunbo_box {
            padding: 20px !important;

            .item {
                display: flex !important;
                flex-direction: column-reverse !important;
                box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15) !important;
                margin-bottom: 10px !important;
                border-radius: 15px !important;
                overflow: hidden !important;

                .right_img {
                    width: 100% !important;
                    margin-bottom: 0px !important;
                }

                .textneirong {
                    padding: 10px !important;

                    .head_box {
                        margin-bottom: 10px !important;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .content_item {
                        font-size: 16px !important;
                        margin-bottom: 10px !important;
                    }

                    .text_box {
                        font-size: 12px !important;
                        margin-bottom: 10px !important;
                    }

                    .tips_box {
                        margin-bottom: 10px !important;

                        div {
                            margin-right: 10px !important;
                            padding: 2px 8px !important;
                            font-size: 10px !important;
                            background: rgba($color: #000000, $alpha: .1);
                            border-radius: 3px !important;
                            display: inline-block !important;
                        }
                    }
                }

            }
        }

        .tiaomu_box {
            display: none !important;
        }
    }
}



.content {
    width: 100%;
    background: #fff;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1300px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 110px;

                .lunbo_box {
                    padding: 20px;
                    background-color: #fff;
                    position: relative;
                    box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15);
                    border-radius: 20px;

                    .item {
                        display: flex;
                        justify-content: space-between;

                        .right_img {
                            width: 500px;
                            height: 363px;
                            border-radius: 20px;
                        }

                        .head_box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .content_item {
                            font-size: 26px;
                            font-weight: 500;
                            color: #000000;
                            margin-top: 24px;
                            // 超出两行省略号
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                        }

                        .text_box {
                            font-size: 18px;
                            font-weight: 400;
                            color: #666666;
                            margin-top: 24px;
                        }

                        .btn_box {
                            width: 153px;
                            height: 38px;
                            border: 2px solid #005083;
                            border-radius: 19px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            font-size: 18px;
                            font-weight: bold;
                            color: #333333;
                            margin-top: 35px;
                            cursor: pointer;

                            img {
                                width: 23px;
                            }
                        }



                    }

                    .active_item {
                        position: absolute;
                        left: 20px;
                        bottom: 11px;
                        display: flex;
                        align-items: center;

                        .active_item_div {
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                            background: #000;
                            margin-right: 30px;
                            cursor: pointer;
                        }

                        .active_item_div_active {
                            width: 12px;
                            height: 12px;
                            background: #005083;
                        }
                    }

                }

                .tiaomu_box {
                    .tiaomu_item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 40px;
                        cursor: pointer;

                        .tiapmu_item_img {
                            width: 342px;
                            height: 227px;
                            border-radius: 20px;
                        }

                        .tiaomu_right_box {
                            flex: 1;
                            margin-left: 39px;

                            .tiaomu_right_box_heads {
                                display: flex;
                                align-items: center;


                            }

                            .content_title {
                                // 超出两行省略号
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }

                            .tips_box {
                                display: flex;
                                align-items: center;
                                margin-top: 35px;
                                flex-wrap: wrap;

                                div {
                                    margin-right: 30px;
                                    margin-bottom: 10px;
                                    padding: 4px 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #333333;
                                    background: rgba($color: #000000, $alpha: .1);
                                    border-radius: 12px;
                                }
                            }
                        }
                    }

                    .fenye {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ::v-deep .el-pagination {
                            display: flex;
                        }

                        ::v-deep .el-pager li.active {
                            color: #fff;
                            background-color: #005083;
                        }

                        ::v-deep .el-pager li {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid #E3E3E3;
                            line-height: 40px;
                            font-size: 16px;
                            margin-right: 10px;
                            font-weight: 100;
                            color: #999999;
                        }

                        ::v-deep .btn-prev {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                            margin-right: 10px;
                        }

                        ::v-deep .btn-next {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                        }

                    }
                }
            }
        }
    }
}
</style>