import axios from 'axios';
import { MessageBox } from 'element-ui'; // 引入 Element UI 的 Message 组件
import router from '@/router'
// 创建一个axios实例
const service = axios.create({
  // baseURL: 'https://aress.jczhilian.com', // 设置请求的基础URL
  baseURL: ' https://admin.aerss.net', // 设置请求的基础URL
  timeout: 1000000000000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些处理，例如设置请求头等
    // config.headers['X-Token'] = getToken();
    // 参数前面为58结尾为64中间为时间戳的sign
    let sign = '58' + new Date().getTime() + '64';
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        sign,
      };
    } else if (config.method === 'post') {
      config.data = {
        ...config.data,
        sign,
      };
    }
    return config;
  },
  error => {
    // 发送请求失败时的处理
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做些处理，例如解析数据、统一错误处理等
    if (response.data.code == 406) {
      MessageBox.confirm('Do you need to login to access the current page and continue', 'Tips', {
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        router.push('/login')
      }).catch(() => {

      });
      return;
    }
    const res = response.data;
    return res;
  },
  error => {
    // 响应错误处理
    return Promise.reject(error);
  }
);

// 封装请求方法
const request = config => {
  return service(config);
};

export default request;
