<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="5"></banner>
            <heads></heads>
            <div class="title">Events</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Events
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ leftMenuData[activeIndex].name }}
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head left_head_active">Events</div>
                    <div class="left_item_box">
                        <div class="item" style="border: none;" @click="toComm1">
                            <div style="margin-left: 20px;">
                                Annual Meeting
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div class="item">
                            <div style="margin-left: 20px;">
                                大气环境遥感与协同分析会议
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-for="(item, key) in leftMenuData">
                            <div class="item" style="border: none;" :class="key == activeIndex ? 'active' : ''"
                                @click="toComm4(item.id)">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div class="item" @click="toComm5">
                            <div style="margin-left: 20px;">
                                AERSS优博奖
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="right_box" v-if="datas">
                    <div v-html="datas.content" class="fwb"></div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
import swiper from '@/components/swiper.vue'
export default {
    components: {
        heads,
        foots,
        banner,
        swiper
    },
    data() {
        return {
            leftMenuData: [],
            datas: null,
            activeIndex: 0
        }
    },
    mounted() {
        this.activeIndex = this.$route.query.id || 0;
        request({
            url: 'apimeeting',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            let arr = []
            res.data.daqilist.forEach(item => {
                arr.push({
                    name: item.title,
                    id: item.id
                })
            })
            this.leftMenuData = [...arr]
            this.$router.push({
                path: '/Committees4',
                query: {
                    id: this.leftMenuData[this.activeIndex].id
                }
            })
            this.datas = res.data.daqi
        })
    },
    methods: {
        toComm1() {
            this.$router.push({
                path: '/AnnualMeeting2024',
            })
        },
        toComm5() {
            this.$router.push({
                path: '/Committees5',
            })
        },
        toComm4(id) {
            if (!id) return;
            this.$router.push({
                path: '/Committees4',
                query: {
                    id: id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    div {
        word-break: break-all;
    }

    .body_box {
        margin-top: 0 !important;
    }


}

div {
    font-weight: 100 !important;
}


.content {
    width: 100%;
    background: #fff;

    a {
        // 清除a标签的下划线
        text-decoration: none;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 60px;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 30px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    padding: 0 20px;
                    width: 317px;
                    height: 115px;
                    font-size: 25px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E5E5E5;
                    cursor: pointer;
                    text-align: center;
                }

                .left_head_active {
                    background: #005083;
                    color: #FFFFFF;
                    border-radius: 20px 20px 0px 0px;

                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 80px;

                .fwb {
                    ::v-deep table {
                        max-width: 720px !important;

                        ::v-deep span {
                            text-align: center
                        }
                    }

                    ::v-deep p {
                        max-width: 100%;
                    }

                    ::v-deep img {
                        max-width: 100%;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
</style>