<template>
    <div class="foot_content" v-if="datas">
        <div class="foot_box">
            <div v-for="(item, key) in footJson" :key="key" v-if="!item.jiaoyan || (item.jiaoyan && userinfo)">
                <div class="th" @click="toPath(item)">{{ item.th }}</div>
                <div class="td">
                    <div v-for="(items, keys) in item.td" :key="keys" @click="toPath(items)" class="noneFont">{{
                        items.text }}</div>
                </div>
            </div>
        </div>
        <div class="foot_box1" style="margin-bottom: 0;">
            <div class="foot_box1_content">
                <div class="left">
                    <div>
                        <img class="logo" @click="toHome" src="@/assets/index/foot_logo.png" alt="">
                    </div>
                    <div style="display: flex;align-items: center;">
                        Follow Us
                        <el-popover placement="top" trigger="hover">
                            <img class="icon" style="width: 150px;" :src="datas.footer_logo" alt="">
                            <img slot="reference" class="icon" src="@/assets/index/weixin.png" alt="">
                        </el-popover>
                        <img slot="reference" class="icon phoneWx" src="@/assets/index/weixin.png" alt="">

                        <a :href="item.title ? item.title : null" target="_blank" v-for="item in tticonarr">
                            <img class="icon" :src="item.photo" alt="">
                        </a>
                    </div>
                    <div style="margin-top: 10px;font-size: 14px;" class="noneFont">
                        {{ datas.footer_str }}
                    </div>
                    <a :href="datas.footer_url ? datas.footer_url : null" target="_blank"
                        style="display: block;max-width: 500px;margin-top: 20px;font-size: 14px;color: rgba(255, 255, 255, 0.5);"
                        class="noneFont">
                        {{ datas.footer_wenzi ? datas.footer_wenzi : '' }}
                    </a>
                </div>
                <div class="mianzebox">
                    <span
                        style="cursor: pointer;margin-left: 80px;display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center;"
                        class="mianze" @click="toDisclaimer">
                        Disclaimer（免责声明）
                    </span>
                </div>
                <div class="right">
                    <div class="qr_box">
                        <img :src="datas.footer_logo" alt="">
                        {{ datas.focus }}
                    </div>
                    <div class="text">
                        <div class="noneFont">
                            Tel: {{ datas.footer_tel }} Fax: {{ datas.footer_fax }}
                        </div>
                        <div class="noneFont">
                            {{ datas.footer_address }}
                        </div>
                        <div class="noneFont">
                            {{ datas.footer_email }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clickTop" @click="toTop" v-if="topShow">
            <i class="el-icon-upload2"></i>
        </div>
    </div>
</template>
<script>
import request from '@/utils/request'
export default {
    data() {
        return {
            footJson: [],
            datas: null,
            userinfo: null,
            tticonarr: [],
            topShow: false
        }
    },
    mounted() {
        this.getdatas()
        this.gettuite()
        this.getMenus()
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'))
        // 判断超出一屏显示返回顶部
        window.addEventListener('scroll', () => {
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            if (top > 400) {
                this.topShow = true;
            } else {
                this.topShow = false;
            }
        });
    },
    methods: {
        getMenus() {
            let MenuItem = JSON.parse(localStorage.getItem('MenuItem'))
            MenuItem.forEach(item => {
                item.th = item.name
                item.url = item.url
                if (item.children.length == 0) {
                    delete item.children
                } else {
                    item.td = item.children
                    item.children.forEach(item2 => {
                        item2.futitle = item.name
                        item2.fuurl = item.url
                        item2.text = item2.name
                        item2.url = item2.url
                    })
                }
            })
            request({
                url: 'api_type',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                MenuItem.forEach(item => {
                    if (item.name === 'Resources') {
                        item.url = '/Publications?type=' + res.data[0].type
                        item.children = res.data.map(item2 => {
                            return {
                                name: item2.value,
                                url: '/Publications?type=' + item2.type,
                                futitle: item.name,
                                fuurl: item.url,
                                text: item2.value
                            }
                        })
                        item.td = item.children
                    }
                })
            })
            this.footJson = MenuItem
            console.log(this.footJson)
        },
        toDisclaimer() {
            if (this.$route.path === '/disclaimer') {
                return;
            }
            this.$router.push('/disclaimer')
        },
        toPath(item) {
            console.log(item)
            if (item.typestatus == 1) {
                this.$router.push({
                    name: 'newList',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 2) {
                this.$router.push({
                    name: 'newMembermList',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 3) {
                this.$router.push({
                    name: 'type3Detail',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 4) {
                this.$router.push({
                    name: 'based_observation',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 5) {
                this.$router.push({
                    name: 'Observation_network',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 6) {
                this.$router.push({
                    name: 'Satellites',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 7) {
                this.$router.push({
                    name: 'Downloads',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.name == 'Resources' || item.futitle == 'Resources') {
                this.$router.push({
                    path: item.url,
                    query: item
                });
                return
            }
            if (!item.url) {
                return;
            }
            // 如果是当前页面 不跳转
            if (this.$route.path === item.url) {
                if (item.md) {
                    this.$emit('toPath', item.md);
                    return;
                }
                return;
            }
            this.$router.push({
                path: item.url,
                query: {
                    type: item.id,
                    futitle: item.futitle,
                    title: item.name,
                    fuurl: item.fuurl
                }
            });
        },
        toHome() {
            if (this.$route.path === '/') {
                return;
            }
            this.$router.push('/')
        },
        getdatas() {
            request({
                url: 'init_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.datas = res.data
            })
        },
        gettuite() {
            request({
                url: 'api_footerpic_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.tticonarr = res.data
            })
        },
        toTop() {
            // 匀速到顶部
            let timer = setInterval(() => {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                let speed = top / 5;
                document.documentElement.scrollTop = top - speed;
                if (top === 0) {
                    clearInterval(timer);
                }
            }, 30);
        }
    }
}
</script>
<style lang="scss" scoped>
.foot_content {
    width: 100%;
    min-height: calc(80vh);
    padding: 50px 0;
    background-color: #004E80;
    display: flex;
    align-items: center;
    flex-direction: column;

    // 缩小
    .foot_box {
        width: 85%;
        display: flex;
        justify-content: space-between;
        transform: scale(0.9);

        .th {
            font-weight: bold;
            color: #FFFFFF;
            line-height: 30px;
            font-size: 18px !important;
            cursor: pointer;
        }

        .td {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 16px !important;
            color: #FFFFFF;

            div {
                color: #fff;
                cursor: pointer;
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                &:hover {
                    color: #ccc;
                }

                span {
                    margin-top: -5px;
                }
            }
        }
    }

    .foot_box>div {
        flex: 1;
    }

    .foot_box1 {
        transform: scale(0.9);
        width: 100%;
        border-top: 1px solid rgba($color: #fff, $alpha: .2);
        margin-top: 30px;
        margin-bottom: 35px;
        display: flex;
        justify-content: center;

        .foot_box1_content {
            width: 85%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 32px;
            font-size: 16px;
            font-weight: bold;
            color: rgba($color: #fff, $alpha: .5);
            line-height: 24px;

            .left {
                .logo {
                    width: 128px;
                    margin-bottom: 25px;
                    cursor: pointer;
                }

                .icon {
                    width: 26px;
                    margin-left: 24px;
                }
            }

            .right {
                display: flex;
                justify-content: space-around;
                flex: 1;
                margin-left: 75px;

                .qr_box {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    max-height: 126px;

                    img {
                        width: 110px;
                        height: 110px;
                    }

                    &:hover {
                        animation: shake 0.5s ease;
                    }
                }

                @keyframes shake {
                    0% {
                        transform: translate(0, 0);
                    }

                    25% {
                        transform: translate(-2px, 0);
                    }

                    50% {
                        transform: translate(2px, 0);
                    }

                    75% {
                        transform: translate(-2px, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                .text {
                    margin-left: 34px;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

        }
    }
}

.mianzebox {
    display: flex;
    flex-direction: column;
}

.clickTop {
    transform: scale(0.9);

    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004E80;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
    position: fixed;
    right: 10px;
    top: 70%;
    box-shadow: 0 0 10px 0 rgba(253, 249, 249, 0.6);
}

.phoneWx {
    display: none;
}

@media screen and (max-width: 1025px) {
    .phoneWx {
        display: block;
    }

    .foot_content {
        max-height: max-content !important;
        padding: 0 !important;
        transform: scale(1) !important;
    }

    .th {
        text-align: left !important;
    }

    .td> {
        div {
            text-align: left !important;
        }
    }

    .mianzebox {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mianze {
        width: 100%;
        margin-left: 0 !important;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .foot_box {
        width: 90% !important;
        flex-direction: column;

        transform: scale(1) !important;

        .th {
            margin-top: 30px !important;
            text-align: center;
        }

        .td {
            display: flex;
            flex-direction: row !important;
            // 超出换行
            flex-wrap: wrap;
            justify-content: flex-start !important;
            margin-top: 0 !important;

            div {
                width: 50% !important;
                margin-top: 20px !important;
                text-align: center;
                font-size: 16px !important;
            }
        }
    }

    .foot_box1_content {
        flex-direction: column !important;
        align-items: center !important;

        .left {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                span {
                    display: none;
                }
            }
        }

        .right {
            margin: 0 !important;
            margin-top: 20px !important;
            flex-direction: column !important;

            .qr_box {
                display: inline-flex !important;
                margin: auto !important;
                margin-bottom: 20px !important;
            }

            .text {
                margin: 0 !important;
                font-size: 14px !important;

            }
        }
    }
}
</style>